import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import inside from '../../assets/images/spinner-pnw-inside.svg';
import outsidee from '../../assets/images/spinner-pnw-outside.svg';

const Spinner = styled.div`
	@keyframes spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	display: relative;
  position: absolute;
	right: 15%;
	top: 30%;
	z-index: 9;

	.Spinner__inside,
	.Spinner__outside {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	.Spinner__outside {
		animation: spin 20s linear infinite;
		width: 125px;
	}

	.Spinner__inside {
		width: 60px;
		top: 32px;
		left: 32px;
	}

	&:hover {
		cursor: pointer;
	}

	@media (max-width: 1000px) {
		right: 25%;
		top: 60%;
	}

	@media (max-width: 650px) {
		display: none;
	}
`;

interface SpinnerProps {}

const SpinnerComponent: FC<SpinnerProps> = (props) => {
	return (
		<Link to="/contact">
			<Spinner>
				<img src={inside} className="Spinner__inside" />
				<img src={outsidee} className="Spinner__outside" />
			</Spinner>
		</Link>
	)
}

export default SpinnerComponent;