import React from 'react';
import { Link } from "gatsby";
import styled from 'styled-components';
import logo from '../../assets/images/pnw_gold.svg';
import ig from '../../assets/images/icon-ig.svg';
import email from '../../assets/images/icon-email.svg';

const Footer = styled.footer`
  background: #101119;
  padding: 3em 2rem;

  &:after {
    content: " ";
    display:block;
    position: absolute;
    top:0px;
    left:0px;
    width:100%;
    height:36px;
    background: linear-gradient(rgba(0,0,0,0) 0%, transparent 0%), linear-gradient(127deg, #101119 33.33%, transparent 33.33%) 0 0%, #101119 linear-gradient(53deg, #101119 33.33%, #fff 33.33%) 0 0%;
    background: -webkit-linear-gradient(rgba(0,0,0,0) 0%, transparent 0%), -webkit-linear-gradient(127deg, #101119 33.33%, transparent 33.33%) 0 0%, #101119 -webkit-linear-gradient(53deg, #101119 33.33%, #fff 33.33%) 0 0%;
    background: -o-linear-gradient(rgba(0,0,0,0) 0%, transparent 0%), -o-linear-gradient(127deg, #101119 33.33%, transparent 33.33%) 0 0%, #101119 -o-linear-gradient(53deg, #101119 33.33%, #fff 33.33%) 0 0%;
    background: -moz-linear-gradient(rgba(0,0,0,0) 0%, transparent 0%), -moz-linear-gradient(127deg, #101119 33.33%, transparent 33.33%) 0 0%, #101119 -moz-linear-gradient(53deg, #101119 33.33%, #fff 33.33%) 0 0%;
        background: -ms-linear-gradient(rgba(0,0,0,0) 0%, transparent 0%), -ms-linear-gradient(127deg, #101119 33.33%, transparent 33.33%) 0 0%, #101119 -ms-linear-gradient(53deg, #101119 33.33%, #fff 33.33%) 0 0%;
    background-repeat: repeat-x;
    background-size: 0 100%,55px 38px,55px 38px
  }
`;

const Container = styled.div`
  color: #fff;
  max-width: 1366px;
  padding: 2rem 0;
  width: 100%;
  margin: 0 auto;

  .Footer__main {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #333;
    margin: 0 auto;
    padding: 2rem 0 4rem;

    @media (max-width: 900px) {
      flex-direction: column;
      text-align: center;
      justify-content: center;
    }
  }

  .Footer__links {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 5rem;
    width: 100%;

    h3 {
      color: #393F4E;
      font-size: 1.5rem;
    }

    @media (max-width: 900px) {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      padding: 0;

			p {
				font-size: 18px;
			}
    }
  }

  .Footer__linkslist {
    margin: 0;

    a {
      color: #fff;
      display: block;
      text-decoration: none;
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 1rem;
      transition: all ease-in-out .3s;

      &:hover {
        color: #C29953;
      }
    }

    @media (max-width: 900px) {
      margin: 1rem 0 0;
    }
  }

  .Footer__logo {
    align-self: flex-start;
    width: 80px;

    @media (max-width: 900px) {
      margin: 0 auto;
    }
  }

  .Footer__small {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: bold;
    justify-content: space-between;
    letter-spacing: .5px;
    padding-top: 2rem;
    text-transform: uppercase;

    @media (max-width: 900px) {
      flex-direction: column;
      text-align: center;
      justify-content: center;
    }
  }

  .Footer__copyright {
    display: flex;
    flex-direction: row;

    @media (max-width: 900px) {
      flex-direction: column;
      text-align: center;
      justify-content: center;
    }
  }
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  position: relative;
  top: 2px;
  
  img {
    width: 25px;
    margin: 0 .5rem;
  }
  
  @media (max-width: 900px) { 
    margin: 1rem auto 0;
  }
`;

function FooterComponent() {
  const date = new Date();
  const year = date.getFullYear()
  return (
		<Footer>
			<Container>
        <div className="Footer__main">
          <img src={logo} alt="PNW Sports Group - Logo" className="Footer__logo" />

          <div className="Footer__links">
            <div className="Footer__linkslist">
              <h3>Company</h3>
              <Link to="/about">About</Link>
              <Link to="/services">Services</Link>
              <Link to="/clients">Clients</Link>
              <Link to="/contact">Contact</Link>
              <Link to="/privacy">Privacy</Link>
            </div>

            <div className="Footer__linkslist">
              <h3>Connect</h3>
              <p>
                info@pnwsportsgroup.com <br />
              </p>
            </div>
            
            <div className="Footer__linkslist">
              <h3>Headquarters</h3>
              <p>
                Vancouver,<br />
                BC, Canada
              </p>
            </div>
          </div>
        </div>

        <div className="Footer__small">
          <p></p>

          <div className="Footer__copyright">
            <p>Copyright PNW Sports Group {year}</p>
            <Icons>
              <a href="https://www.instagram.com/PNWSPORTSGROUP">
                <img src={ig} alt="Instagram" />
              </a>
            </Icons>
          </div>
        </div>
			</Container>
		</Footer>
  );
}

export default FooterComponent;
