import { createGlobalStyle } from 'styled-components';

export const theme = {
  font: {
    sans: 'sans-serif',
  },
  color: {
    primary: '#101119',
    secondary: '#ccc',
  },
};

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    background: #101119;
    height: 100%;
    width: 100%;
    line-height: 1.5;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  h1 {
    color: #fff;
    font-size: 3.5rem;
    line-height: 1.5;
    margin: 0;
    text-shadow: 5px 8px 10px rgba(0,0,0,.3);
  
    @media (max-width: 500px) {
      font-size: 2rem;
    }
  }

  h2 {
    color: #fff;
    font-size: 2.5rem;
    line-height: 1.5;
    margin: 0;
  
    @media (max-width: 500px) {
      font-size: 1.5rem;
    }
  }

  footer {
    background: #101119;
    z-index: 2;
    position: relative;
  }

  #app {
    background-color: #fff;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    line-height: 1.7em;
  }

  h1, h2 {
    font-family: 'Averia Serif Libre';
  }

	.hidden-sm {
		@media (max-width: 650px) {
			display: none;
		}
	}

	.visible-sm {
		@media (min-width: 651px) {
			display: none;
		}
	}
`;
