import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { GlobalStyle, theme } from './src/global-styles';
import Header from './src/components/Header';
import Footer from './src/components/Footer';
import Spinner from './src/components/Spinner';
import favicon from './src/assets/images/favicon/favicon.ico';

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Averia+Serif+Libre&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap"
          rel="stylesheet"
        ></link>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, target-densityDpi=device-dpi, minimal-ui"
        />
        <meta
          name="description"
          content="We are an athlete representation and management business founded in the Pacific Northwest."
        />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header />
        <Spinner />
        {element}
        <Footer />
      </ThemeProvider>
    </>
  );
};
