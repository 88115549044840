import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { globalHistory } from '@reach/router'
import logo from '../../assets/images/pnw_gold.svg';
import ig from '../../assets/images/icon-ig.svg';
import email from '../../assets/images/icon-email.svg';

const Header = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 10;
  padding: 2rem;

  .logo {
    margin: 0;
    width: 120px;

		@media (max-width: 1000px) {
			width: 80px;
		}
  }

  .Header__linkslist {
    line-height: 2;
    font-size: 2rem;
    font-weight: 700;
    margin: 8rem 0 4rem;

    a {
      color: #fff;
      text-decoration: none;
      display: block;
      margin: 2rem 0;
      text-align: center;
    }

    @media (max-width: 1366px) {
      a {
        margin: 1rem 0;
      }
    }
  }

	@media (max-width: 1000px) {
		padding: 1rem;
	}
`;

const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;

  svg {
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.active svg {
    transform: rotate(90deg);

    path {
      stroke: #fff;
    }
  }

  path {
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  path:nth-child(1) {
    transform-origin: 36% 40%;
  }

  path:nth-child(2) {
    stroke-dasharray: 29 299;
  }

  path:nth-child(3) {
    transform-origin: 35% 63%;
  }

  path:nth-child(4) {
    stroke-dasharray: 29 299;
  }

  path:nth-child(5) {
    transform-origin: 61% 52%;
  }

  path:nth-child(6) {
    transform-origin: 62% 52%;
  }

  &.active path:nth-child(1) {
    transform: translateX(9px) translateY(1px) rotate(45deg);
  }

  &.active path:nth-child(2) {
    stroke-dasharray: 225 299;
    stroke-dashoffset: -72px;
  }

  &.active path:nth-child(3) {
    transform: translateX(9px) translateY(1px) rotate(-45deg);
  }

  &.active path:nth-child(4) {
    stroke-dasharray: 225 299;
    stroke-dashoffset: -72px;
  }

  &.active path:nth-child(5) {
    transform: translateX(9px) translateY(1px) rotate(-45deg);
  }

  &.active path:nth-child(6) {
    transform: translateX(9px) translateY(1px) rotate(45deg);
  }
`;

const Menu = styled.nav`
  background: #101119;
  color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 9;
  transition: all ease-in-out .3s;
  opacity: 0;

  &.active {
    transform: translateX(0);
    opacity: 1;
  }
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  
  img {
    width: 25px;
    margin: 0 1.5rem;
  }
`;

function HeaderComponent() {
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setActive(false)
    })
  }, [setActive])

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <Header>
      <Link to="/">
        <img src={logo} alt="PNW Sports Group - Logo" className="logo" />
      </Link>

      <MenuButton onClick={() => toggleClass()} className={isActive ? 'active' : ''}>
        <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 200 200" className="hidden-sm">
          <g stroke-width="6.5" stroke-linecap="round">
            <path
              d="M72 82.286h28.75"
              fill="#009100"
              fill-rule="evenodd"
              stroke="#101119"
            />
            <path
              d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
              fill="none"
              stroke="#101119"
            />
            <path
              d="M72 125.143h28.75"
              fill="#009100"
              fill-rule="evenodd"
              stroke="#101119"
            />
            <path
              d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
              fill="none"
              stroke="#101119"
            />
            <path
              d="M100.75 82.286h28.75"
              fill="#009100"
              fill-rule="evenodd"
              stroke="#101119"
            />
            <path
              d="M100.75 125.143h28.75"
              fill="#009100"
              fill-rule="evenodd"
              stroke="#101119"
            />
          </g>
				</svg>
				
				<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 200 200" className="visible-sm">
          <g stroke-width="6.5" stroke-linecap="round">
            <path
              d="M72 82.286h28.75"
              fill="#009100"
              fill-rule="evenodd"
              stroke="#101119"
            />
            <path
              d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
              fill="none"
              stroke="#101119"
            />
            <path
              d="M72 125.143h28.75"
              fill="#009100"
              fill-rule="evenodd"
              stroke="#101119"
            />
            <path
              d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
              fill="none"
              stroke="#101119"
            />
            <path
              d="M100.75 82.286h28.75"
              fill="#009100"
              fill-rule="evenodd"
              stroke="#101119"
            />
            <path
              d="M100.75 125.143h28.75"
              fill="#009100"
              fill-rule="evenodd"
              stroke="#101119"
            />
          </g>
        </svg>
      </MenuButton>

      <Menu className={isActive ? 'active' : ''}>
        <div className="Header__linkslist">
          <Link to="/about">About</Link>
          <Link to="/services">Services</Link>
          <Link to="/clients">Clients</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/privacy">Privacy</Link>
        </div>

        <Icons>
          <a href="https://www.instagram.com/PNWSPORTSGROUP">
            <img src={ig} alt="Instagram" />
          </a>
        </Icons>
      </Menu>
    </Header>
  );
}

export default HeaderComponent;
